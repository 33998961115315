<script setup lang="ts">
import type { ILocation } from '@/types/types'
import { Cookie, EventName, LocationType, SessionStorageKey } from '@/types/enums'

defineEmits(['showModal', 'closeModal'])

const locationParam = useCookie(Cookie.LocationParam)

const { status } = useAsyncData('location', async () => {
  let response = await $fetch<ILocation>('/api/location', {
    query: {
      location: locationParam.value,
    },
  })

  response = snakeToCamel(response)

  if (response?.slug && response?.postalCode) {
    track(EventName.ChangeLocation, {
      citySlug: useLocation().value?.slug,
      postalCode: useLocation().value?.postalCode,
    })
  }

  const { fullPath, params } = useRoute()

  const newPath = fullPath.replace(params.location as string, locationParam.value)
  useRouter().options.history.replace(newPath)

  useLocation().value = response
}, {
  dedupe: 'defer',
  watch: [locationParam],
})

const modalIsVisible = ref<boolean>(false)
const geolocationPopup = useSessionStorage<boolean>(SessionStorageKey.GeolocationPopup, false)

const label = computed(() => {
  const locationState = useLocation()

  switch (useLocation().value?.type) {
    case LocationType.CITY:
      return locationState.value?.showName
    case LocationType.GEOLOCATION:
      return locationState.value?.showName
    case LocationType.ZIP_CODE:
      return locationState.value?.postalCode
    default:
      return null as never
  }
})

function geolocation() {
  navigator.geolocation.getCurrentPosition(async ({ coords }) => {
    const { latitude: lat, longitude: lng } = coords

    locationParam.value = `${lat.toString()},${lng.toString()}`
  })

  modalIsVisible.value = false
}

function openLocationModal() {
  modalIsVisible.value = true
  geolocationPopup.value = false
}

function closeGeolocationPopupInSession() {
  geolocationPopup.value = false
}
</script>

<template>
  <div class="flex items-center">
    <button
      id="button-select-location"
      aria-label="Botão para selecionar cidade"
      class="bg-primary-100 flex items-center px-3 py-2 rounded-lg text-primary-400"
      type="button"
      @click="modalIsVisible = true"
    >
      <span class="sr-only">Botão para selecionar a cidade.</span>

      <Icon v-if="status === 'pending'" name="mdi:loading" class="size-6 animate-spin" />
      <Icon v-else name="mdi:map-marker-outline" class="size-6" />

      <span
        class="hidden lg:inline w-full lg:text-sm mx-2 font-sora leading-5 text-sm"
      >
        {{ label }}
      </span>

      <Icon name="mdi:chevron-down" class="size-6 lg:size-5" />
    </button>

    <PrimeDialog
      v-model:visible="modalIsVisible"
      modal
      dismissable-mask
      :draggable="false"
      class="mx-4 lg:mx-0"
    >
      <template #header>
        <h4 class="text-primary-400 font-sora leading-5">Onde você deseja realizar seus exames?</h4>
      </template>

      <template #closeicon>
        <Icon name="mdi:close" class="size-4 text-primary-400" />
      </template>

      <div
        class="flex flex-col items-center relative lg:w-[690px] w-full duration-300"
      >
        <div class="flex flex-col items-start w-full">
          <label for="input-search-location" class="text-secondary-300 font-sora text-sm mb-1">
            Cidade ou CEP:
          </label>

          <InputSearchLocation
            id="input-search-location"
            v-model="locationParam"
            @select-location="modalIsVisible = false"
          />
        </div>

        <button
          v-if="useLocation().value?.type !== LocationType.GEOLOCATION"
          class="mt-4 rounded-lg cursor-pointer bg-bg-50 px-5 py-4 w-full flex items-center"
          @click="geolocation"
        >
          <Icon name="mdi:target" class="w-6 h-6 text-primary-400" />
          <span class="ml-4 text-primary-400 text-sm font-semibold font-sora">
            Usar a minha localização
          </span>
        </button>
      </div>
    </PrimeDialog>

    <div
      v-if="geolocationPopup"
      class="absolute w-[313px] bg-white shadow-lg rounded-lg top-[80px] lg:top-[90px] right-[16px] lg:-ml-[70px] pt-3 pb-2 px-4"
    >
      <span class="font-semibold text-primary-500 text-sm">
        Confira a distância para o seu endereço
      </span>
      <p class="text-primary-500 text-sm mt-1">
        Inclua seu CEP ou localização para verificar a distância precisa na sua
        busca.
      </p>
      <div class="flex items-center mt-3">
        <Button class="mr-5" @click="openLocationModal">
          Incluir CEP
        </Button>

        <span class="text-primary-400 font-semibold text-sm cursor-pointer" @click="closeGeolocationPopupInSession">
          Mais tarde
        </span>
      </div>
    </div>
  </div>
</template>
