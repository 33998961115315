<script setup lang="ts">
import type { ICart } from '@/types/types'
import { LocalStorageKey } from '@/types/enums'

const cart = useLocalStorage(LocalStorageKey.Cart, {} as ICart)
</script>

<template>
  <button
    aria-label="Link para acessar orçamentos"
    type="button"
    class="flex-row-reverse relative text-secondary-300 cursor-pointer disabled:cursor-not-allowed disabled:text-primary-500 disabled:opacity-[0.16] p-1 flex items-center gap-2"
    :disabled="!cart || !cart.cartDetails?.length"
    @click="navigateTo('/checkout')"
  >
    <span
      v-if="cart?.cartDetails?.length"
      class="lg:absolute left-0 top-0 flex items-center justify-center text-sm font-semibold text-white bg-information-500 min-w-[16px] min-h-[16px] w-4 h-4 rounded-full"
    >
      {{ cart?.cartDetails?.length }}
    </span>

    <IconProceduresResume class="hidden lg:block" />

    <span class="inline lg:hidden ml-3">Orçamentos</span>
  </button>
</template>
