<script setup lang="ts">
import type { IMenuLink } from '@/types/types'

defineProps({
  isCheckout: {
    type: Boolean,
    default: false,
  },
})

const content = useContent()

interface ISocialLink {
  text: string
  icon: string
  link: string
  alt: string
}
const socialLinks = computed<ISocialLink[]>(() => [
  {
    text: 'Blog',
    icon: 'mdi:blogger',
    link: content.value.blogUrl,
    alt: `Botão de link para o Blog d${content.value.labArticle} ${content.value.sellerName}`,
  },
  {
    text: 'Facebook',
    icon: 'mdi:facebook-box',
    link: content.value.facebookUrl,
    alt: `Botão de link para o Facebook d${content.value.labArticle} ${content.value.sellerName}`,
  },
  {
    text: 'Instagram',
    icon: 'mdi:instagram',
    link: content.value.instagramUrl,
    alt: `Botão de link para o Instagram d${content.value.labArticle} ${content.value.sellerName}`,
  },
  {
    text: 'LinkedIn',
    icon: 'mdi:linkedin',
    link: content.value.linkedinUrl,
    alt: `Botão de link para o Linkedin d${content.value.labArticle} ${content.value.sellerName}`,
  },
].filter(s => s.link))

const links = computed<IMenuLink[]>(() => {
  const baseLinks: IMenuLink[] = [
    {
      link: '/quem-somos',
      target: '_self',
      text: 'Quem Somos',
      items: [],
      trevoContent: true,
    },
    {
      link: '/compra-garantida',
      text: 'Compra Garantida',
      items: [],
      trevoContent: true,
    },
    {
      link: '/pagamento-protegido',
      target: '_self',
      text: 'Pagamento Protegido',
      items: [],
      trevoContent: false,
    },
    {
      link: '/faq',
      target: '_self',
      text: 'FAQ',
      items: [],
      trevoContent: true,
    },
    {
      link: '/politicas-de-privacidade',
      text: 'Politícas de Privacidade',
      items: [],
      trevoContent: true,
    },
    {
      link: '/termos-e-condicoes',
      text: 'Termos e Condições',
      items: [],
      trevoContent: true,
    },
  ]

  if (!isTrevo())
    return baseLinks.filter(l => !l.trevoContent)

  return baseLinks
})
</script>

<template>
  <footer class="py-10 md:py-20 bg-secondary-500">
    <div id="trevo-footer" class="container mx-auto px-5 lg:px-0 flex flex-col">
      <Logo dark class="mb-6" />

      <p v-if="isTrevo()" class="inline-block text-base-50 text-base font-sora font-normal pb-4 mb-3 lg:mt-6">
        Uma boa saúde começa com <b>boas escolhas!</b>
      </p>

      <ul v-if="!isCheckout" class="flex flex-col md:flex-row flex-wrap xl:flex-nowrap justify-between gap-1 pt-4 mb-3 border-t border-secondary-400">
        <li v-for="(item, i) in links" :key="i" class="flex items-center md:first:-ml-3">
          <NuxtLink
            v-if="item.link"
            :to="item.link"
            :target="item.target"
            :alt="`link para ${item.text}`"
            no-prefetch
            class="text-base-50 text-sm font-sora font-semibold py-[10px] pr-4 md:pl-3 whitespace-nowrap"
          >
            {{ item.text }}
          </NuxtLink>
        </li>
        <li class="pt-5 md:pt-0 relative">
          <LoginMenu alternate />
        </li>
      </ul>

      <div v-if="content.footerOpeningHours" class="flex flex-col md:flex-row gap-6 md:gap-0 justify-between pt-4 mb-3 border-t border-secondary-400">
        <div class="gap-4 items-center">
          <h4 class="md:whitespace-nowrap text-base-50 text-base font-sora font-semibold">
            Horário de atendimento:
          </h4>
          <p class="md:text-left text-base-50 text-xs font-normal w-full">
            {{ content.footerOpeningHours }}
          </p>
        </div>

        <NuxtLink
          v-if="content.reclameAquiUrl"
          :to="content.reclameAquiUrl"
          target="_blank"
          alt="tag do reclame aqui"
          external
          no-prefetch
        >
          <Img
            src="https://web-cdn.saudetrevo.com.br/illustrations/reclame_aqui.svg"
            :alt="`Selo de qualidade d${content.labArticle} ${content.sellerName} no Reclame Aqui`"
            width="110"
            height="28"
          />
        </NuxtLink>
      </div>

      <div class="flex flex-col md:flex-row gap-8 md:gap-0 justify-between lg:py-3 border-t border-secondary-400">
        <p class="text-base-50 text-xs font-sora">
          <span v-if="!isTrevo()" class="block font-semibold">
            Trevo Web Engine
          </span>
          <span v-if="content.companyName && content.taxId" class="block">
            {{ `Razão Social: ${content.companyName} – CNPJ: ${content.taxId}.` }}
          </span>
          <span v-if="content.sellerName">
            {{ `© ${getCurrentYear()} ${content.sellerName} – Todos os direitos reservados.` }}
          </span>
        </p>

        <ul class="flex gap-2">
          <li v-for="(social, i) in socialLinks" :key="i">
            <NuxtLink
              :to="social.link"
              :aria-label="social.alt"
              target="_blank"
              class="flex justify-center items-center bg-base-50 text-center text-secondary-500 rounded-full
                cursor-pointer size-7 border border-base-50 hover:bg-secondary-500 hover:text-base-50 transition-colors"
            >
              <Icon
                :alt="social.text"
                :name="social.icon"
                class="w-5 h-5"
              />
            </NuxtLink>
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>
