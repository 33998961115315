<script lang="ts" setup>
import { LocalStorageKey } from '@/types/enums'

defineProps({
  alternate: {
    type: Boolean,
    default: false,
  },
})

const accessToken = useLocalStorage(LocalStorageKey.AccessToken, '')
const { logout, showSignInModal } = useAuth()
const { customer } = useCustomer()

const name = computed(() => {
  if (!customer.value.name)
    return ''

  return `${customer.value.name || ''} ${customer.value.lastName || ''}`
})

const showProfileTooltip = ref(false)
function toggleProfileTooltip() {
  showProfileTooltip.value = !showProfileTooltip.value
}

function openSignInModal() {
  showSignInModal.value = true
}

function handleLogout() {
  showProfileTooltip.value = false
  logout()
}

const profileTooltip = ref()

onClickOutside(profileTooltip, () => {
  showProfileTooltip.value = false
})
</script>

<template>
  <div id="button-user-login">
    <div v-if="accessToken">
      <div
        v-if="!alternate"
        class="flex lg:hidden text-primary-500 min-w-[20px] min-h-[20px] size-10 xl:size-auto items-center justify-center"
        @click="toggleProfileTooltip"
      >
        <Icon name="mdi:account-circle-outline" class="w-6 h-6" />
      </div>

      <button
        class="hidden lg:inline-block min-w-40 h-[40px] px-5 rounded-md border text-sm font-semibold
        transition-all duration-280"
        :class="alternate ? 'border-white text-white hover:bg-primary-500 !inline-block' : 'border-primary-400 text-primary-400 hover:bg-primary-400 hover:text-white'"
        @click="toggleProfileTooltip"
      >
        Olá, {{ name || 'paciente' }}
      </button>

      <div
        ref="profileTooltip"
        class="h-fit flex w-[calc(100vw_-_2rem)] lg:w-fit min-w-[175px] z-50 absolute right-0
              py-1 bg-white rounded-md border border-neutral-50 flex-col"
        :class="{
          'hidden': !showProfileTooltip,
          'top-[calc(100%_+_1.5rem)]': !alternate,
          'bottom-[calc(100%_+_1.5rem)]': alternate,
        }"
        :content="() => 'Olá, {{ name }}'"
      >
        <NuxtLink
          to="/perfil"
          no-prefetch
          aria-label="Link para acessar o perfil do usuário"
          class="text-neutral-400 text-sm font-sora gap-2 px-4 py-3 flex"
          @click="toggleProfileTooltip"
        >
          <Icon name="mdi:account-outline" class="text-primary-400" />
          Meu perfil
        </NuxtLink>
        <NuxtLink
          to="/perfil/meus-pedidos"
          no-prefetch
          aria-label="Link para acessar o perfil do usuário"
          class="text-neutral-400 text-sm font-sora gap-2 px-4 py-3 flex"
          @click="toggleProfileTooltip"
        >
          <Icon name="mdi:format-list-bulleted" class="text-primary-400" />
          Meus pedidos
        </NuxtLink>
        <NuxtLink
          to="/"
          no-prefetch
          aria-label="Link para acessar o perfil do usuário"
          class="text-neutral-400 text-sm font-sora gap-2 px-4 py-3 flex"
          @click="handleLogout"
        >
          <Icon name="mdi:logout" class="text-primary-400" />
          Sair
        </NuxtLink>
      </div>
    </div>

    <div v-else class="flex items-center cursor-pointer">
      <div
        class="flex lg:hidden min-w-[20px] min-h-[20px] size-10 xl:size-auto items-center justify-center"
        :class="alternate
          ? 'hidden'
          : 'text-primary-300'"
        @click="openSignInModal"
      >
        <Icon name="mdi:account-circle-outline" class="w-6 h-6" />
      </div>

      <button
        id="btn-user-login"
        class="hidden lg:inline-block h-[40px] rounded-md border border-primary-400 text-primary-400 text-sm font-semibold
            transition-all duration-280"
        :class="alternate
          ? 'hover:bg-primary-500 border-white text-white !inline-block px-9'
          : 'hover:bg-primary-400 hover:text-white border-primary-400 text-primary-400 min-w-40 px-5'"
        @click="openSignInModal"
      >
        Entrar
      </button>
    </div>
  </div>
</template>
