<script setup lang="ts">
defineProps({
  isCheckout: {
    type: Boolean,
    default: false,
  },
})

const mobileMenuOpenned = ref(false)

function toggleMobileMenu(): void {
  mobileMenuOpenned.value = !mobileMenuOpenned.value
}

const content = useContent()
</script>

<template>
  <header
    id="trevo-header"
    class="fixed top-0 z-30 w-full h-20 flex items-center "
    :class="isCheckout ? 'bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-30 bg-primary-50 text-primary-400 shadow-md' : 'bg-white border-b border-base-50'"
  >
    <div class="container mx-auto flex items-center justify-between px-4 md:px-5 xl:px-0 py-2">
      <div class="flex items-center gap-6">
        <Logo id="logo-header" />

        <HeaderNavLinks v-if="!isCheckout" id="header-navigation" class="hidden lg:flex" />
      </div>

      <div v-if="isCheckout" id="secure-checkout" class="flex justify-center items-center">
        <Icon name="mdi:lock-outline" class="w-6 h-6 text-primary-400" />
        Checkout seguro
      </div>

      <nav id="header-actions" class="flex items-center lg:gap-6 relative">
        <ModalLocation v-if="!isCheckout && content.multiLocation" />

        <CartButton v-if="!isCheckout" class="hidden lg:block" />

        <LoginMenu />

        <NavButton
          v-if="!isCheckout"
          :open="mobileMenuOpenned"
          class="lg:hidden"
          @clicked="toggleMobileMenu"
        />
      </nav>

      <HeaderDropdownMenu :opened-modal="mobileMenuOpenned" @close="mobileMenuOpenned = !mobileMenuOpenned" />
    </div>
  </header>
</template>
