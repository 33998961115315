<script setup lang="ts">
import type { ICategory, ICategoryItem, IMenuLink } from '@/types/types'
import { Cookie } from '@/types/enums'

defineEmits(['close'])

const locationParam = useCookie(Cookie.LocationParam)

const opened = ref(false)

const { data, error } = useFetch<ICategory>('/v1/marketplace/categories', {
  baseURL: useRuntimeConfig().public.api.awsGateway,
  headers: {
    origin: useRuntimeConfig().public.buildContent.sellerDns,
  },
})

const content = useContent()
const links = computed<IMenuLink[]>(() => {
  const baseLinks: IMenuLink[] = []

  if (isTrevo()) {
    baseLinks.push({
      text: 'Quem somos',
      link: '/quem-somos',
      target: '_self',
      external: false,
      trevoContent: true,
    })
  }

  if (content.value.blogUrl) {
    baseLinks.push({
      text: 'Blog',
      link: content.value.blogUrl,
      target: '_blank',
      external: true,
      trevoContent: false,
    })
  }

  if (isMultiLab()) {
    baseLinks.push({
      text: 'FAQ',
      link: '/faq',
      target: '_self',
      external: false,
      trevoContent: false,
    })
  }

  if (!error.value && data.value?.items && data.value.items.length > 0) {
    baseLinks.splice(0, 0, {
      text: 'Exames',
      link: '',
      trevoContent: false,
      items: data.value.items.map((item: ICategoryItem) => ({
        text: item.name,
        link: `/${locationParam.value}/categorias/${item.slug}`,
        target: '_self',
        external: false,
      })),
    })
  }

  return baseLinks.filter(l => l.link || l.items)
})
</script>

<template>
  <ul class="flex flex-col md:items-center gap-6 lg:flex-row lg:gap-0 w-full">
    <li
      v-for="(item, index) in links"
      :key="`link-${index}`"
      class="flex"
    >
      <NuxtLink
        v-if="item.link"
        class="p-4 text-xs lg:text-sm font-normal text-neutral-200"
        active-class="font-bold"
        :external="item.external"
        :to="item.link"
        :target="item.target"
        no-prefetch
        @click="$emit('close')"
      >
        {{ item.text }}
      </NuxtLink>

      <div v-else class="group py-3 pr-4 pl-0 md:pl-4 relative">
        <div
          class="flex justify-between items-center cursor-pointer gap-3 text-neutral-200"
          @click="opened = !opened"
        >
          <span
            class="text-sm font-normal"
          >
            {{ item.text }}
          </span>

          <Icon
            name="mdi:chevron-down"
            :class="{ 'rotate-180 xl:rotate-0 group-hover:xl:rotate-180': opened, 'rotate-0 group-hover:xl:rotate-180': !opened }"
            class="w-5 h-5 duration-300"
          />
        </div>

        <ul
          :class="{ hidden: !opened, flex: opened }"
          class="w-full lg:hidden group-hover:lg:flex flex-col lg:bg-base-50 mt-2 lg:absolute xl:w-[300px] rounded lg:shadow divide-y divide-neutral-50"
        >
          <NuxtLink
            v-for="(subItem, itemIndex) in item.items"
            :key="itemIndex"
            class="font-normal text-sm hover:lg:bg-primary-400 hover:lg:text-base-50 text-neutral-100 py-4 px-6 cursor-pointer first:hover:lg:rounded last:hover:lg:rounded"
            :to="subItem.link"
            :target="subItem.target"
            :external="subItem.external"
            @click="$emit('close')"
          >
            {{ subItem.text }}
          </NuxtLink>
        </ul>
      </div>
    </li>

    <slot name="more-options" />
  </ul>
</template>
